.banner{
    &-title{
        letter-spacing: 1rem;
        text-shadow: black 0.2em 0.2em 0.3em;
    }
}
.iframe-rwd {
    position: relative;
    padding-bottom: 50%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
    }
    .iframe-rwd iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    }

    .cardHover{
      
        &:hover{
            opacity: .7;
           
        }
    }