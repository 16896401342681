@mixin sm{
    @media (min-width: 576px){
        @content
    }
}
@mixin md{
    @media (min-width: 768px){
        @content
    }
}
@mixin lg{
    @media (min-width: 992px){
        @content
    }
}